import React from 'react';
import styled from 'styled-components/macro';

import { MainDiv } from '../../components/ProfileCardV2/styles';

import Slider from '../LandingPage/Slider';
import TestimonialCard from './TestimonialCardDialog';

import fontSize from '../../assets/fontSize';

const TestimonialsWrapper = styled.section`
  padding: 2rem 0;
  margin-top: 3rem;
`;
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;
const Title = styled.h3`
  margin: 0;
  font-size: ${fontSize.oneHalfRem};
  text-align: center;

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.twoRem};
  }
`;

const options = {
  wrapAround: true,
  freeScroll: false,
  fullscreen: true,
  adaptiveHeight: true,
  draggable: true,
  pageDots: true,
  contain: true,
  prevNextButtons: true
};

const Testimonials = ({ data }) => {
  return (
    <TestimonialsWrapper>
      <Content>
        <Title>Testimonials</Title>
        <MainDiv style={{ margin: '3rem auto' }} scroll>
          <Slider options={options}>
            {data?.map(testimonial => {
              return (
                <TestimonialCard
                  key={testimonial.companyName}
                  data={testimonial}
                />
              );
            })}
          </Slider>
        </MainDiv>
      </Content>
    </TestimonialsWrapper>
  );
};

export default Testimonials;

import React from 'react';
import styled from 'styled-components/macro';

import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Divider from '@material-ui/core/Divider';

import HeaderSeo from '../../components/HeaderSeo';
import Layout from '../../components/Layout';

import AdvantagesOfGoget from '../../views/shared/AdvantagesOfGogetV2';
import Benefits from '../../views/Project/Benefits';
import ContactSection from '../../components/ContactSection';
import FAQSection from '../../views/shared/FAQ';
import TopHeader from '../../views/shared/TopHeaderV2';
import ProjectPerks from '../../views/Project/ProjectPerks';

import MetaImg from '../../assets/img/meta/business.png';

import colors from '../../assets/colors';
import Testimonials from '../../views/Project/Testimonials';

const ContactWrapper = styled.section`
  background-color: ${colors.projectBlueLight};
`;
const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: unset;
`;

const FAQData = [
  {
    question: 'What is GoGet Projects?',
    content: (
      <Text>
        GoGet Projects provides an end-to-end account management service where
        we handle recruitment, screening, and management of part-time staff for
        your required job scope.
      </Text>
    )
  },
  {
    question: 'What are the requirements to engage with GoGet Projects?',
    content: (
      <Text>
        To engage with GoGet Projects, you must have a GoGet Business account.
        <br />
        <br />
        The project should have a minimum duration of one month and require at
        least five workers daily.
      </Text>
    )
  },
  {
    question: 'Why should I engage with GoGet Projects?',
    content: (
      <Text>
        GoGet Projects offers customised recruitment solutions tailored to your
        specific needs and goals.
        <br />
        <br />
        You'll have a dedicated point of contact, access to our tech-enabled
        workforce management tools, and comprehensive end-to-end services,
        including screening, onboarding, and payment management.
        <br />
        <br />
        Our approach is adaptive and agile to meet your business requirements,
        saving you hours of precious time and resources.
      </Text>
    )
  },
  {
    question: 'How long does it take to set up and go live?',
    content: (
      <Text>
        Minimally one week, on average between one to two weeks upon project
        confirmation.
      </Text>
    )
  },
  {
    question: 'Do I need to pay any fees for GoGet Projects?',
    content: (
      <Text>
        Project management fees does apply and typically ranges between 20-30%
        of the project value. Additional charges may be applicable, and will be
        quoted prior to the start of the project.
      </Text>
    )
  },
  {
    question: 'Will there be a dedicated manager for my project?',
    content: (
      <Text>
        Yes, you will have a dedicated project manager who will assist you
        throughout the project.
      </Text>
    )
  },
  {
    question: 'How would the payment/salary to workers be managed?',
    content: (
      <Text>
        GoGet will handle and manage payments to workers through the platform
        using GoGet credits, and the payments will be automatically deducted
        from your GoGet wallet.
      </Text>
    )
  }
];

const Project = props => {
  const {
    data: { datoCmsProject }
  } = props;

  const desktopImage = getImage(datoCmsProject?.bannerImageDesktop);
  const mobileImage = getImage(datoCmsProject?.bannerImageMobile);

  return (
    <>
      <HeaderSeo
        title="GoGet | On Demand Part Timers for Businesses"
        description="Our agile team powered by advanced in-house recruitment technology, stands ready to deliver seamless end-to-end solutions for your hiring needs"
        image={MetaImg}
        keywords="goget projects, management, goget business plan, hire part time for business, temp agency, manpower recruitment agency"
        pathname={props.location.pathname}
      />
      <Layout>
        <TopHeader
          backgroundImg={
            <GatsbyImage
              image={desktopImage}
              loading="eager"
              style={{ height: '100%' }}
              objectPosition="left top"
              quality={90}
              alt={datoCmsProject?.bannerImageDesktop.alt}
            />
          }
          mobileBackgroundImg={
            <GatsbyImage
              image={mobileImage}
              loading="eager"
              style={{ height: '100%' }}
              quality={90}
              alt={datoCmsProject?.bannerImageMobile.alt}
            />
          }
          h4="GoGet Projects"
          title={datoCmsProject?.headerTitle}
          description={datoCmsProject?.description}
          btn2={{
            text: 'TALK TO US',
            link: '/business/projects#contact-us'
          }}
          secondary
          main
          showVideo
          hidePlay
        />
        <AdvantagesOfGoget
          textColor={colors.blue}
          data={datoCmsProject?.advantages}
        />
        <ProjectPerks data={datoCmsProject?.perks} />
        <Benefits data={datoCmsProject?.benefits} />
        <Testimonials data={datoCmsProject?.testimonials} />
        <Divider style={{ width: '80%', margin: '0 auto' }} />
        <FAQSection data={FAQData} />
        <ContactWrapper id="contact-us">
          <ContactSection
            title="Let us help grow your business!"
            subtext="Provide us with your details and we will get in touch."
            textBlack
            hideBackground
            projectPage
          />
        </ContactWrapper>
      </Layout>
    </>
  );
};

export default Project;

export const query = graphql`
  query ProjectQuery {
    datoCmsProject {
      headerTitle
      description
      bannerImageDesktop {
        gatsbyImageData(placeholder: BLURRED)
        alt
      }
      bannerImageMobile {
        gatsbyImageData(width: 600, placeholder: BLURRED)
        alt
      }
      advantages {
        image {
          gatsbyImageData(width: 200, placeholder: BLURRED)
          alt
        }
        header
        description
      }
      perks {
        image {
          gatsbyImageData(width: 1800, placeholder: BLURRED)
          alt
        }
        title
        content
      }
      benefits {
        description
        selfServe
        projects
      }
      testimonials {
        headerImage {
          gatsbyImageData(width: 500, placeholder: BLURRED)
          alt
        }
        companyName
        jobTitle
        statistics {
          value
          description
        }
        challenge
        solution
        services {
          image {
            gatsbyImageData(width: 200, placeholder: BLURRED)
            alt
          }
          serviceType
        }
      }
      schemaMarkup
    }
  }
`;

import styled from 'styled-components/macro';
import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';

export const MainDiv = styled.div`
  display: ${props => !props.scroll && 'flex'};
  justify-content: center;
  flex-wrap: wrap;
  width: ${props => (!props.scroll ? '100%' : '960px')};
  margin: auto;
  @media (max-width: 1016px) {
    width: unset;
    margin: unset;
  }
  .flickity-prev-next-button.previous {
    left: -46px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1016px) {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1016px) {
      display: none;
    }
  }
  .flickity-page-dots .dot {
    background: ${colors.primary};
  }
`;
export const BannerImg = styled.div`
  border-radius: 10px 10px 0px 0px;
  width: 100%;
  @media (max-width: 500px) {
    height: 205px;
  }
`;
export const ContentDiv = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 6px;
`;
export const DescriptionText = styled.p`
  font-size: ${fontSize.large};
  margin: 10px 0px 10px 0px;
  font-weight: 300;
  flex: 1;
  min-height: 65px;
`;
export const Link = styled.div`
  font-size: ${fontSize.large};
  color: ${colors.primary};
  margin: 16px 0px 16px 0px;
  align-items: center;
  display: flex;
  text-transform: uppercase;
`;
export const SliderWrapper = styled.div`
  border-radius: 1rem !important;
  overflow: hidden !important;
  .flickity-viewport {
    height: 400px !important;
    width: 78% !important;
    margin: auto !important;
  }
  .flickity-page-dots {
    position: unset !important;
  }
`;
export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  .MuiSvgIcon-root {
    color: ${props => props.active && colors.primary};
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const Item = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 0px 13px;
  margin-top: 6px;
  font-size: ${fontSize.large};
`;
export const CollaspContainer = styled(ContentDiv)`
  position: absolute;
  background: white;
  width: 250px;
  z-index: 1;
  border-radius: 20px;
  @media (max-width: 780px) {
    position: unset;
    width: 300px !important;
  }

  a {
    color: ${colors.primary};
    text-decoration: unset;
  }
`;
export const ModalContainer = styled.div`
  padding: 10px 22px 10px 22px;
  subText {
    font-weight: 400;
    font-size: ${fontSize.large};
    margin-top: 5px;
    display: inline-block;
  }
  quote {
    color: ${colors.secondary};
    font-size: ${fontSize.large};
    font-style: italic;
  }
  a {
    color: ${colors.primary};
    text-decoration: unset;
  }
`;
export const ModalHeader = styled(Header)`
  img {
    margin-right: 5px;
    height: 71px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
export const SliderDiv = styled.div`
  margin-bottom: 5px;
  .slick-dots li button:before {
    font-size: 10px;
    line-height: 10px;
    color: #00b4ad;
    margin-top: 2px;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: flex;
    align-items: center;
  }
  .slick-next {
    display: none !important;
  }
  .iframe {
    height: 300px;
  }
`;
export const StyledPlayIcon = styled(PlayIcon)`
  font-size: 5rem !important;
  color: ${colors.white};
  cursor: pointer;
  position: absolute;
  top: 34%;
  bottom: 0;
  left: 14%;
  right: 0;
  width: max-content;
`;

import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Close from '@material-ui/icons/Close';
import ChevronRight from '@material-ui/icons/ChevronRight';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const StyledCard = styled(Card)`
  max-width: 300px;
  width: 100%;
  margin: 1.5rem 2.5rem 1.5rem 1.5rem;
  min-height: 460px;
`;
const ImageWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.greyLight};
  height: 150px;
`;
const CompanyTitle = styled.p`
  margin: 0;
  font-size: ${fontSize.xLarge};
  font-weight: 700;
`;
const JobPosition = styled.p`
  margin: 0;
  font-size: ${fontSize.xLarge};
  font-weight: 600;
`;
const Subtitle = styled.p`
  margin: 0;
  font-size: ${fontSize.large};
  font-weight: 700;
`;
const DetailsText = styled.p`
  margin: 0;
  margin-top: 0.25rem;
  font-size: ${fontSize.large};
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineLimit }) =>
    lineLimit}; /* number of lines to show */
  line-clamp: ${({ lineLimit }) => lineLimit};
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
`;
const ServicesTitle = styled.p`
  margin: 0;
  font-size: ${fontSize.xLarge};
  font-weight: 600;
`;
const Content = styled.div`
  padding: 1rem;
`;
const StatisticWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  justify-content: space-evenly;
`;
const StatisticBox = styled.div`
  padding: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
`;
const StatisticValue = styled.p`
  margin: 0;
  font-size: ${fontSize.oneHalfRem};
  color: ${colors.blue};
  font-weight: 700;
`;
const StatisticDescription = styled.p`
  margin: 0;
  font-size: ${fontSize.medium};
  text-align: center;
`;
const ButtonWrapper = styled.div`
  padding: 1rem 0;
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const ServicesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem 0;
`;
const ServiceBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const ServiceType = styled.p`
  margin: 0;
  font-size: ${fontSize.large};
  font-weight: 400;
`;
const ContactUsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const TestimonialCard = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const headerImage = getImage(data.headerImage);

  const handleContactUs = () => {
    setIsOpen(false);
    setTimeout(() => {
      const topOfElement =
        document.querySelector('#contact-us').offsetTop - 170;
      window.scroll({ top: topOfElement, behavior: 'smooth' });
    }, 500);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        scroll="paper"
        maxWidth="xs"
        fullWidth
      >
        <ImageWrapper>
          <CloseButtonWrapper>
            <IconButton onClick={() => setIsOpen(false)}>
              <Close />
            </IconButton>
          </CloseButtonWrapper>
          <GatsbyImage
            image={headerImage}
            alt={data?.headerImage?.alt}
            objectFit="contain"
            style={{ height: '100%' }}
          />
        </ImageWrapper>
        <Content>
          <CompanyTitle>{data.companyName},</CompanyTitle>
          <JobPosition>{data.jobTitle}</JobPosition>
        </Content>
        <Divider />
        <Content>
          <StatisticWrapper>
            {data?.statistics?.map(statistic => {
              return (
                <StatisticBox key={statistic.description}>
                  <StatisticValue>{statistic.value}</StatisticValue>
                  <StatisticDescription>
                    {statistic.description}
                  </StatisticDescription>
                </StatisticBox>
              );
            })}
          </StatisticWrapper>
        </Content>
        <Divider />
        <Content>
          <Subtitle>Business Challenge:</Subtitle>
          <DetailsText>{data.challenge}</DetailsText>
        </Content>
        <Content>
          <Subtitle>GoGet Solution:</Subtitle>
          <DetailsText>{data.solution}</DetailsText>
        </Content>
        <Divider />
        <Content>
          <ServicesTitle>Services used on GoGet:</ServicesTitle>
          <ServicesWrapper>
            {data?.services?.map(service => {
              return (
                <ServiceBox key={service.serviceType}>
                  <GatsbyImage
                    image={getImage(service.image)}
                    alt={service.image.alt}
                    objectFit="contain"
                    style={{ height: '48px', width: '48px' }}
                  />
                  <ServiceType>{service.serviceType}</ServiceType>
                </ServiceBox>
              );
            })}
          </ServicesWrapper>
          <ContactUsWrapper>
            <Button
              color="primary"
              variant="contained"
              style={{ color: colors.white }}
              onClick={handleContactUs}
            >
              Contact me
            </Button>
          </ContactUsWrapper>
        </Content>
      </Dialog>
      <StyledCard elevation={5}>
        <ImageWrapper>
          <GatsbyImage
            image={headerImage}
            alt={data?.headerImage?.alt}
            objectFit="contain"
            style={{
              height: '100%'
            }}
          />
        </ImageWrapper>
        <Content>
          <CompanyTitle>{data.companyName}</CompanyTitle>
          <DetailsText lineLimit={3}>{data.challenge}</DetailsText>
          <StatisticWrapper>
            {data?.statistics?.map(statistic => {
              return (
                <StatisticBox key={statistic.description}>
                  <StatisticValue>{statistic.value}</StatisticValue>
                  <StatisticDescription>
                    {statistic.description}
                  </StatisticDescription>
                </StatisticBox>
              );
            })}
          </StatisticWrapper>
          <ButtonWrapper>
            <Button
              color="primary"
              endIcon={<ChevronRight />}
              onClick={() => setIsOpen(true)}
            >
              More Details
            </Button>
          </ButtonWrapper>
        </Content>
      </StyledCard>
    </>
  );
};

export default TestimonialCard;

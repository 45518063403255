import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';

import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';

import fontSize from '../../assets/fontSize';
import colors from '../../assets/colors';

const BenefitsWrapper = styled.section`
  padding: 2rem 0;
  margin-top: 3rem;
  background-color: ${colors.projectBlueLight};
`;
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;
const Title = styled.h3`
  margin: 0;
  font-size: ${fontSize.oneHalfRem};
  text-align: center;

  @media screen and (min-width: 920px) {
    font-size: ${fontSize.twoRem};
  }
`;
const CardContainer = styled.div`
  padding: 3rem 0;
`;
const MainCard = styled(Card)`
  && {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    overflow: visible;
    background-color: transparent;

    @media screen and (min-width: 920px) {
      background-color: white;
      flex-direction: row;
      gap: 3rem;
    }
  }
`;
const BenefitsColumn = styled.div`
  display: none;

  @media screen and (min-width: 920px) {
    display: flex;
    padding: 1rem;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
  }
`;
const FloatingCard = styled(Card)`
  min-width: 250px;
  min-height: 250px;
  border: ${({ project }) => (project ? `2px solid ${colors.blue}` : 'none')};
  transform: scale(1);
  z-index: 2;

  @media screen and (min-width: 920px) {
    transform: scale(1.05);
  }
`;
const FeatureColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;

  @media screen and (min-width: 920px) {
    padding-top: 1.75rem;
  }
`;
const Row = styled.div`
  min-height: 35px;
  padding: 0.5rem;
  border-radius: ${({ borderTop, borderless }) =>
    borderTop ? '4px 4px 0 0' : !borderless && '8px'};
  background-color: ${({ bgColor, alternate }) =>
    bgColor ?? (alternate ? colors.greyLight : colors.white)};
  display: flex;
  align-items: center;
`;
const BenefitTitle = styled.p`
  width: 100%;
  margin: 0;
  font-size: ${({ columnTitle }) =>
    columnTitle ? fontSize.xxLarge : fontSize.large};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-bottom: ${({ columnTitle }) => (columnTitle ? '0.5rem' : '0')};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  color: ${({ alternative }) => (alternative ? colors.white : colors.black)};

  @media screen and (min-width: 920px) {
    font-weight: ${({ columnTitle }) => (columnTitle ? 700 : 600)};
  }
`;
const BenefitDescription = styled.p`
  margin: 0;
  font-size: ${fontSize.large};
  flex: 1;

  @media screen and (min-width: 920px) {
    display: none;
  }
`;
const BenefitContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
const StyledCheckCircle = styled(CheckCircle)`
  color: ${colors.blue};
`;

const Benefits = ({ data }) => {
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const updateWindowDimensions = () => {
      if (window.innerWidth <= 730 && !mobile) {
        setMobile(true);
      } else if (window.innerWidth > 730 && mobile) {
        setMobile(false);
      }
    };

    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, [mobile]);

  return (
    <BenefitsWrapper>
      <Content>
        <Title>Benefits using GoGet Projects</Title>
        <CardContainer>
          <MainCard elevation={mobile ? 0 : 2}>
            <BenefitsColumn>
              <Row>
                <BenefitTitle columnTitle>✨ Our Benefit</BenefitTitle>
              </Row>
              {data?.map((benefit, index) => {
                return (
                  <Row key={benefit.description} alternate={index % 2 === 1}>
                    <BenefitTitle>{benefit.description}</BenefitTitle>
                  </Row>
                );
              })}
            </BenefitsColumn>
            <FloatingCard elevation={2}>
              <Row bgColor={colors.greyLight} borderTop>
                <BenefitTitle columnTitle bold center>
                  Self-serve
                </BenefitTitle>
              </Row>
              <FeatureColumn>
                {data?.map((benefit, index) => {
                  return (
                    <Row key={benefit.description} alternate={index % 2 === 1}>
                      <BenefitContentWrapper>
                        <BenefitDescription>
                          {benefit.description}
                        </BenefitDescription>
                        {benefit.selfServe ? (
                          <StyledCheckCircle />
                        ) : (
                          <Cancel color="disabled" />
                        )}
                      </BenefitContentWrapper>
                    </Row>
                  );
                })}
              </FeatureColumn>
            </FloatingCard>
            <FloatingCard elevation={10} project>
              <Row bgColor={colors.blue} borderless>
                <BenefitTitle columnTitle center alternative bold>
                  GoGet Projects
                </BenefitTitle>
              </Row>
              <FeatureColumn>
                {data?.map((benefit, index) => {
                  return (
                    <Row key={benefit.description} alternate={index % 2 === 1}>
                      <BenefitContentWrapper>
                        <BenefitDescription>
                          {benefit.description}
                        </BenefitDescription>
                        {benefit.projects ? (
                          <StyledCheckCircle />
                        ) : (
                          <Cancel color="disabled" />
                        )}
                      </BenefitContentWrapper>
                    </Row>
                  );
                })}
              </FeatureColumn>
            </FloatingCard>
          </MainCard>
        </CardContainer>
      </Content>
    </BenefitsWrapper>
  );
};

export default Benefits;
